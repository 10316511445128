import { Currency } from '@pancakeswap/sdk'
import useTheme from 'hooks/useTheme'
import dynamic from 'next/dynamic'
import { useCallback, useState } from 'react'
import getLpAddress from 'utils/getLpAddress'
import BnbWbnbNotice from './BnbWbnbNotice'
import PriceChart from './PriceChart'
import { getTokenAddress } from './utils'

const TVChartContainer = dynamic(() => import('components/TVChartContainer').then((mod) => mod.TVChartContainer), {
  ssr: false,
})

type PriceChartContainerProps = {
  inputCurrencyId: string
  inputCurrency: Currency
  outputCurrencyId: string
  outputCurrency: Currency
  isChartExpanded: boolean
  setIsChartExpanded: React.Dispatch<React.SetStateAction<boolean>>
  isChartDisplayed: boolean
  currentSwapPrice: {
    [key: string]: number
  }
  isMobile?: boolean
  isFullWidthContainer?: boolean
  chainId: number
}

const PriceChartContainer: React.FC<React.PropsWithChildren<PriceChartContainerProps>> = ({
  inputCurrencyId,
  inputCurrency,
  outputCurrency,
  outputCurrencyId,
  isChartExpanded,
  setIsChartExpanded,
  isChartDisplayed,
  isMobile,
  isFullWidthContainer = false,
  currentSwapPrice,
  chainId,
}) => {
  const token0Address = getTokenAddress(inputCurrencyId, chainId)
  const token1Address = getTokenAddress(outputCurrencyId, chainId)
  const pair = getLpAddress(token1Address, token0Address, chainId)

  if (!isChartDisplayed) {
    return null
  }

  return (
    <TVChartContainer
      pair={pair}
      name={`${inputCurrency?.symbol ?? ''}/${outputCurrency?.symbol ?? ''}`}
      token0={token1Address}
      token1={token0Address}
      chainId={chainId}
    />
  )
}

export default PriceChartContainer
