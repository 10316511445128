import { Contract, providers, BigNumber } from 'ethers'
import pairAbi from './abi/IPancakePair.json'
import erc20 from './abi/erc20.json'
import { Erc20, IPancakePair } from './abi/types'
import { bsc } from '@pancakeswap/wagmi/chains'

import { PancakeFactory, PancakeFactory__factory, MockERC1155, MockERC1155__factory } from './typechain'
import { ENV_IS_PROD, NXDT_COIN } from 'utils/env'

const rpc = bsc.rpcUrls.default.http[0]
const provider = new providers.JsonRpcProvider(rpc)

export const ADMIN_ADDRESS = ENV_IS_PROD
  ? '0x5DE64FA9cd3F2a9fEbc71822AA88287f1F34F541'
  : '0x3Ba6810768c2F4FD3Be2c5508E214E68B514B35f' //admin that can disable and reactivate pairs
const FACTORY_ADDRESS = ENV_IS_PROD
  ? '0x63D19A275fd468aA5A29FAc7B14697Ca0b5B3315'
  : '0x8DEF7E10e3FF7B54edA09894A2262046Aa3910b1' //factory address
export const USDC = ENV_IS_PROD
  ? '0x4237e0a5b55233d5b6d6d1d9bf421723954130d8'
  : '0xd501Da8574DfA72B5FAEd70c605aE995898bBA32'
export const USDT = ENV_IS_PROD
  ? '0xdef886c55a79830c47108eeb9c37e78a49684e41'
  : '0x29DFDF9EB0770003fa5bC1e3DF36670A4ba63237'

export const CP_LOGO = 'https://assets.coingecko.com/coins/images/28432/large/IMG_20221207_200805_897.jpg'

export function getPairContract(address: string): IPancakePair {
  return new Contract(address, pairAbi, provider) as IPancakePair
}

export function getTokenContract(address: string): Erc20 {
  return new Contract(address, erc20, provider) as Erc20
}

export async function getUSDCBalance(userAddress: string): Promise<BigNumber> {
  const contract = new Contract(NXDT_COIN, erc20, provider) as Erc20
  return await contract.balanceOf(userAddress)
}

// export async function getCPBalance(userAddress: string): Promise<BigNumber> {
//   const contract = new Contract(TJAY, erc20, provider) as Erc20
//   return await contract.balanceOf(userAddress)
// }

export function getUsdcContract(): Erc20 {
  return new Contract(NXDT_COIN, erc20, provider) as Erc20
}

export function getUsdcContractSigner(signer: any): Erc20 {
  return new Contract(NXDT_COIN, erc20, signer) as Erc20
}

export function getFactoryContract(): PancakeFactory {
  return PancakeFactory__factory.connect(FACTORY_ADDRESS, provider)
}

export function getPairContractSigner(pairAddr: string, signer: any): IPancakePair {
  return new Contract(pairAddr, pairAbi, signer) as IPancakePair
}

// export function getErc1155Contract(): MockERC1155 {
//   return MockERC1155__factory.connect(ERC1155_CONTRACT, provider)
// }

// export function getErc1155ContractSigner(signer: any): MockERC1155 {
//   return MockERC1155__factory.connect(ERC1155_CONTRACT, signer)
// }

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms))
