import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M2.40078 2.3999H0.800781V15.1999H15.2008V13.5999H2.40078V2.3999Z" 
        fill={props.fill}/>
      <path 
        d="M8.79922 8.8L6.39922 7.2L3.19922 10.4V12.8H14.3992V4L8.79922 8.8Z" 
        fill={props.fill}/>
    </svg>
  );
};

export default Icon;
