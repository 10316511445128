import {
  ChartDisableIcon,
  ChartIcon,
  Swap,
  Flex,
  HistoryIcon,
  RefreshIcon,
  IconButton,
  NotificationDot,
  useModal,
  useMatchBreakpoints,
} from '@pancakeswap/uikit'
import TransactionsModal from 'components/App/Transactions/TransactionsModal'
import GlobalSettings from 'components/Menu/GlobalSettings'
import { ReactElement, useCallback, useContext, useState } from 'react'
import { useExpertModeManager } from 'state/user/hooks'
import styled from 'styled-components'
import { SettingsMode } from '../../../components/Menu/GlobalSettings/types'
import { SwapFeaturesContext } from '../SwapFeaturesContext'
import useTheme from 'hooks/useTheme'

interface Props {
  title: string | ReactElement
  subtitle: string
  noConfig?: boolean
  setIsChartDisplayed?: React.Dispatch<React.SetStateAction<boolean>>
  isChartDisplayed?: boolean
  hasAmount: boolean
  onRefreshPrice: () => void
}

const ColoredIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.textSubtle};
  background: ${({ theme }) => theme.colors.input};
  height: 32px;
  width: 32px;
  border-radius: 8px;
`

const FlexWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  @media (max-width: 575px) {
    justify-content: start;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    margin-top: 8px;
  }
`
const FlexIcons = styled(Flex)`
  width: 40%;
  @media (max-width: 575px) {
    margin-top: 12px;
    width: 60%;
  }
`

const CurrencyInputHeader: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  subtitle,
  hasAmount,
  onRefreshPrice,
}) => {
  const { isChartSupported, isChartDisplayed, setIsChartDisplayed } = useContext(SwapFeaturesContext)
  const [expertMode] = useExpertModeManager()
  const { theme } = useTheme()
  const { isMobile } = useMatchBreakpoints()
  const toggleChartDisplayed = () => {
    setIsChartDisplayed((currentIsChartDisplayed) => !currentIsChartDisplayed)
  }
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />)
  const handleOnClick = useCallback(() => {
    onRefreshPrice?.()
    setSpining(true)
    setTimeout(() => setSpining(false), 2000)
  }, [onRefreshPrice])
  const [spining, setSpining] = useState(false)
  return (
    <Swap.CurrencyInputHeader
      title={
        <Flex flexDirection="column" alignItems="start" width="100%" mr={26}>
          <Swap.CurrencyInputHeaderTitle>{title}</Swap.CurrencyInputHeaderTitle>
        </Flex>
      }
      icons={
        <FlexWrapper>
          {!isMobile && <Swap.CurrencyInputHeaderSubTitle>{subtitle}</Swap.CurrencyInputHeaderSubTitle>}
          <FlexIcons justifyContent="space-between" width="40%">
            {isChartSupported && setIsChartDisplayed && (
              <ColoredIconButton onClick={toggleChartDisplayed} variant="text" scale="sm">
                {isChartDisplayed ? (
                  <ChartDisableIcon width="24px" fill={theme.colors.primary} />
                ) : (
                  <ChartIcon width="24px" fill={theme.colors.swapIcon} />
                )}
              </ColoredIconButton>
            )}
            <NotificationDot show={expertMode}>
              <GlobalSettings mr="0" mode={SettingsMode.SWAP_LIQUIDITY} color={theme.colors.swapIcon} />
            </NotificationDot>
            <IconButton onClick={onPresentTransactionsModal} variant="text" scale="sm">
              <HistoryIcon color="primaryDark" width="20px" fill={theme.colors.swapIcon} />
            </IconButton>
            <IconButton variant="text" scale="sm" onClick={handleOnClick}>
              <RefreshIcon
                spin={spining}
                duration={1}
                disabled={!hasAmount}
                width="20px"
                fill={theme.colors.swapIcon}
              />
            </IconButton>
          </FlexIcons>
        </FlexWrapper>
      }
    />
  )
}

export default CurrencyInputHeader
