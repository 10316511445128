import { useState, useContext } from 'react'
import styled from 'styled-components'
import { useTranslation } from '@pancakeswap/localization'
import { SwapFeaturesContext } from '../SwapFeaturesContext'

export enum SwapType {
  SWAP,
  STABLE_SWAP,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const SwapTitle = styled.div`
  width: 137px;
  height: 48px;
  font-weight: 600;
`
const SwapDescription = styled.div`
  height: 48px;
  width: 100%;
  line-height: 18px;
  background: ${({ theme }) => theme.colors.backgroundGrey};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGrey};
  text-align: center;
  /* padding: 0 90px; */
`

export default function SwapTab({ children }) {
  const { isStableSupported } = useContext(SwapFeaturesContext)
  const { t } = useTranslation()
  const [swapTypeState, setSwapType] = useState(SwapType.SWAP)

  if (isStableSupported) {
    return (
      <>
        {/* <Wrapper><SwapDescription>{t('Decentralized Individual Asset Exchange')}</SwapDescription></Wrapper> */}
        {children(swapTypeState)}
      </>
    )
  }

  return children(SwapType.SWAP)
}
