import { Box, Flex } from '@pancakeswap/uikit'
import styled from 'styled-components'

export const StylesSwapWrapper = styled(Flex)<{ $isChartDisplayed: boolean }>`
  max-width: ${({ $isChartDisplayed }) => ($isChartDisplayed ? '444px' : 'none')};
`

export const StyledSwapContainer = styled(Flex)<{ $isChartDisplayed: boolean }>`
  flex-shrink: 0;
  height: fit-content;
  padding: 0;
  width: 100%;
  justify-content: center;

  ${({ theme }) => theme.mediaQueries.lg} {
    ${({ $isChartDisplayed }) => ($isChartDisplayed ? 'padding: 0 0 0 24px' : 'padding: 0 40px')};
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    ${({ $isChartDisplayed }) => ($isChartDisplayed ? 'padding: 0 0 0 24px' : 'padding: 0 120px')};
  }
`

export const StyledInputCurrencyWrapper = styled(Box)`
  max-width: 444px;
  width: 100%;
  @media (max-width: 480px) {
    /* max-width: 348px; */
  }
`
