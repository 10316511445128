/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../common";
import type { Mock, MockInterface } from "../../../contracts/OldMock.sol/Mock";

const _abi = [
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "caller_",
        type: "address",
      },
    ],
    name: "mockfn",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "pure",
    type: "function",
  },
] as const;

const _bytecode =
  "0x608060405234801561001057600080fd5b5060e78061001f6000396000f3fe6080604052348015600f57600080fd5b506004361060285760003560e01c80636a1c5d9214602d575b600080fd5b605d60048036036020811015604157600080fd5b503573ffffffffffffffffffffffffffffffffffffffff166071565b604080519115158252519081900360200190f35b6000733ba6810768c2f4fd3be2c5508e214e68b514b35f73ffffffffffffffffffffffffffffffffffffffff83161460a957600060ac565b60015b9291505056fea265627a7a723158201b2a97a42e636ff0d212a1a3f5416042f3072b8ac090148eb712d91ec020149c64736f6c63430005100032";

type MockConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: MockConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class Mock__factory extends ContractFactory {
  constructor(...args: MockConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<Mock> {
    return super.deploy(overrides || {}) as Promise<Mock>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): Mock {
    return super.attach(address) as Mock;
  }
  override connect(signer: Signer): Mock__factory {
    return super.connect(signer) as Mock__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): MockInterface {
    return new utils.Interface(_abi) as MockInterface;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): Mock {
    return new Contract(address, _abi, signerOrProvider) as Mock;
  }
}
