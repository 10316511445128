import { Box, Card, Text, Button, ArrowForwardIcon, Row } from '@pancakeswap/uikit'
import styled from 'styled-components'

const Wrapper = styled(Card)`
  display: flex;
  justify-content: center;
  background-image: url('/images/background-stake.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 8px 0;
  img {
    @media screen and (max-width: 575px) {
      :nth-child(1) {
        width: 20px;
      }
      :nth-child(2) {
        width: 40px;
      }
      :nth-child(3) {
        width: 20px;
      }
      :nth-child(4) {
        width: 26px;
      }
    }
    position: absolute;
    z-index: -1;
  }
`

const StakeNow: React.FC<React.PropsWithChildren> = () => {
  return (
    <Wrapper mb="24px">
      <img style={{ left: '25px', top: '42px' }} src="/images/kujata-1.png" />
      <img style={{ left: '41px', top: '5px' }} src="/images/kujata-2.png" />
      <img style={{ right: '45px', top: '28px' }} src="/images/kujata-3.png" />
      <img style={{ right: '10px', top: '52px' }} src="/images/kujata-4.png" />

      <Text fontFamily="IBM Plex Mono" fontWeight="700" fontSize="20px" textAlign="center">
        Get FTN for Liquidity{' '}
      </Text>
      <Text fontFamily="IBM Plex Mono" fontStyle="italic" fontSize="16px" textAlign="center">
        up to 300% APR
      </Text>
      <Row style={{ fontFamily: 'IBM Plex Mono' }} mt="8px" gap="16px" justifyContent="center">
        <Button scale="smxs" variant="stake">
          <a href="https://staking.kujata.io/">Stake now</a>
        </Button>
        <Button scale="smxs" variant="learn">
          <a
            style={{ alignItems: 'center', display: 'flex', gap: '8px' }}
            href="https://shrub-wren-e75.notion.site/Kujata-io-Trade-FTN-on-Bahamut-chain-26b9c148b9f64aab89e8cf08357586e4"
            target="_blank"
          >
            Learn how <ArrowForwardIcon />
          </a>
        </Button>
      </Row>
    </Wrapper>
  )
}

export default StakeNow
